<template>
	<Menu actual="/asesoria-psicologica" class="mt-6" />

	<div class="bg-white">
		<div class="max-w-7xl mx-auto py-16 px-4 sm:py-28 sm:px-6 lg:px-8">
			<div class="sm:grid grid-cols-2">
				<div class="sm:px-10">
					<div class="text-center">
						<h2 class="text-sm font-semibold text-morado tracking-wide uppercase">Estamos juntos</h2>
						<p class="mt-1 text-2xl font-extrabold text-cyan sm:text-3xl sm:tracking-tight">Asesoría en educación sexual</p>
						<p class="max-w-md mt-2 mx-auto text-base text-gray-500">Adriana Torres, fundadora de Cuéntame Cómo.</p>
					</div>

					<div class="flex justify-center mt-12">
						<img class="rounded-full border-2 border-cyan w-36 h-36" :src="require('@/assets/acerca-de/adriana-torres.jpg')" alt="Adriana Torres">
					</div>

					<div class="text-center">
						<p class="max-w-xl mt-6 mx-auto text-base text-azul font-semibold">
							Conferencias y talleres
						</p>

						<p class="max-w-md mt-1 mx-auto text-base text-gray-500 leading-snug">
							Sobre educacion sexual infantil, prevencion de abuso y talleres para niños.
						</p>

						<div class="flex items-end justify-center space-x-3 text-left mt-8">
							<PhoneIcon class="w-6 h-6 text-cyan" />
							<div>
								<div class="text-xs font-medium text-gray-500">LLÁMANOS</div>
								<div class="font-medium text-cyan">442 586 98 84</div>
							</div>
							
						</div>
					</div>
				</div>

				<div class="sm:px-10">
					<div class="text-center">
						<h2 class="text-sm font-semibold text-morado tracking-wide uppercase">Estamos juntos</h2>
						<p class="mt-1 text-2xl font-extrabold text-verde sm:text-3xl sm:tracking-tight">Asesoría psicológica</p>
						<p class="max-w-md mt-2 mx-auto text-base text-gray-500">Licenciada en psicología clínica Alejandra Fernández.</p>
					</div>

					<div class="flex justify-center mt-12">
						<img class="rounded-full border-2 border-verde w-36 h-36" :src="require('@/assets/asesoria-psicologica/alejandra-fernandez.jpg')" alt="Lic. Alejandra Fernández">
					</div>

					<div class="text-center">
						<p class="max-w-md mt-6 mx-auto text-base text-azul font-semibold">
							Apoyo en
						</p>

						<p class="max-w-md mt-1 mx-auto text-base text-gray-500 leading-snug">
							Detección sobre abuso sexual infantil o dudas sobre un posible abuso. Dudas en general.
						</p>

						<div class="flex items-end justify-center space-x-3 text-left mt-8">
							<PhoneIcon class="w-6 h-6 text-verde" />
							<div>
								<div class="text-xs font-medium text-gray-500 ">LLÁMANOS</div>
								<div class="font-medium text-verde">442 352 21 67</div>
							</div>
							
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>


<script>
import { PhoneIcon } from '@heroicons/vue/outline'
import Menu from '@/components/core/Menu'

export default {
	components: {
		Menu,
		PhoneIcon
	}
}
</script>